import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 346.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)">


<path d="M925 3124 c-308 -178 -640 -370 -737 -426 l-178 -102 0 -867 0 -867
117 -68 c111 -63 696 -400 1156 -666 128 -74 214 -118 226 -115 18 4 730 412
1224 701 l258 151 0 865 0 865 -738 427 c-406 235 -745 427 -753 427 -8 0
-267 -146 -575 -325z m1310 -142 l710 -411 0 -842 0 -842 -320 -185 c-176
-102 -501 -289 -722 -417 l-401 -232 -724 417 -723 418 -3 840 -2 839 637 368
c351 202 674 390 718 416 44 27 89 47 100 45 11 -2 340 -188 730 -414z"/>
<path d="M864 2571 c-2 -2 -4 -292 -4 -643 l0 -639 393 3 392 3 66 26 c146 56
237 135 296 257 l38 77 0 135 c0 134 0 135 -36 210 -43 90 -135 187 -215 226
-109 53 -178 64 -426 64 -255 0 -245 3 -208 -71 37 -71 61 -79 247 -79 233 0
313 -22 401 -111 98 -100 124 -252 64 -389 -26 -61 -116 -142 -190 -172 -54
-22 -71 -23 -364 -26 l-308 -3 0 493 0 493 310 -2 c346 -3 394 -9 515 -68 243
-117 381 -372 347 -642 -7 -50 -21 -116 -32 -146 -52 -139 -185 -283 -315
-342 -133 -60 -122 -59 -520 -65 -350 -5 -371 -6 -397 -25 -30 -22 -62 -84
-56 -110 3 -15 34 -16 386 -15 474 1 525 9 697 105 248 138 389 385 388 680 0
304 -155 554 -427 690 -157 78 -158 78 -624 84 -228 3 -416 4 -418 2z m751
-32 c39 -5 79 -11 90 -13 11 -2 27 -4 35 -5 11 -2 9 -5 -5 -11 -15 -7 -9 -9
25 -9 25 -1 66 -9 92 -19 41 -15 45 -19 30 -29 -14 -10 -11 -12 21 -12 20 -1
37 -5 37 -11 0 -5 9 -10 20 -10 11 0 40 -20 65 -43 25 -24 46 -42 49 -40 3 3
20 -16 91 -102 14 -16 25 -36 25 -42 0 -7 5 -13 10 -13 6 0 10 -14 10 -31 0
-16 4 -28 9 -24 6 3 15 -3 20 -14 12 -22 41 -123 47 -161 2 -14 8 -45 13 -70
19 -89 -3 -287 -37 -340 -9 -14 -15 -33 -14 -42 2 -10 -2 -18 -7 -18 -6 0 -11
-9 -11 -20 0 -11 -4 -20 -9 -20 -5 0 -11 -10 -15 -23 -5 -21 -16 -37 -71 -99
-8 -10 -15 -22 -15 -28 0 -5 -7 -10 -16 -10 -9 0 -18 -8 -21 -17 -2 -10 -26
-34 -53 -53 -27 -19 -50 -38 -50 -42 0 -5 -9 -8 -20 -8 -11 0 -20 -4 -20 -8 0
-4 -13 -16 -30 -25 -16 -10 -30 -15 -30 -12 0 3 -10 -1 -22 -9 -12 -9 -37 -16
-55 -16 -18 0 -33 -4 -33 -10 0 -26 -85 -33 -457 -37 -322 -4 -385 -2 -397 10
-12 12 -11 18 8 42 l22 27 289 0 c309 0 429 8 510 34 66 21 171 72 186 90 6 8
18 14 25 14 8 0 14 4 14 9 0 5 9 13 20 16 11 3 20 11 20 17 0 5 19 29 43 52
27 26 61 77 88 131 24 50 46 92 49 95 9 8 30 170 30 228 0 62 -21 182 -32 182
-5 0 -8 7 -8 17 0 9 -17 50 -37 92 -27 55 -59 97 -113 151 -41 41 -73 79 -72
83 1 5 -3 7 -10 5 -7 -3 -38 10 -70 29 -31 18 -63 33 -71 33 -9 0 -23 7 -31
16 -9 8 -20 13 -25 10 -5 -3 -33 2 -62 11 -45 13 -103 16 -361 15 -228 -1
-313 -5 -328 -14 -20 -13 -20 -22 -20 -498 0 -404 2 -489 14 -506 13 -18 31
-19 278 -22 286 -4 368 4 453 44 73 35 138 102 175 179 31 65 32 69 26 168 -7
126 -10 147 -17 147 -14 0 -56 79 -47 88 6 6 2 11 -11 15 -14 3 -19 1 -15 -6
4 -7 -5 0 -20 16 -15 15 -46 37 -69 48 -23 12 -44 24 -47 28 -3 3 -12 7 -20 8
-8 1 -39 6 -69 12 -29 6 -120 11 -202 11 -93 0 -150 4 -154 11 -4 5 -12 8 -18
6 -20 -6 -47 27 -40 51 4 18 8 20 19 11 11 -9 14 -9 14 0 0 18 357 12 437 -7
34 -8 60 -19 57 -23 -3 -5 5 -9 18 -9 29 0 169 -97 175 -121 3 -10 12 -20 20
-23 9 -4 12 -13 8 -25 -4 -13 0 -20 13 -23 22 -6 68 -134 54 -152 -12 -15 -11
-47 1 -39 16 10 1 -157 -19 -207 -21 -52 -51 -95 -67 -95 -7 -1 -11 -7 -9 -14
4 -17 -18 -42 -30 -35 -5 3 -8 -2 -6 -12 3 -18 -1 -20 -38 -23 -8 0 -12 -5 -9
-11 7 -11 -54 -52 -66 -45 -4 3 -9 -2 -13 -10 -3 -8 -16 -15 -29 -15 -13 0
-28 -5 -33 -10 -19 -19 -184 -30 -461 -30 -212 0 -273 3 -274 13 0 10 -2 10
-6 0 -2 -7 -11 -13 -19 -13 -9 0 -13 4 -10 9 3 5 -3 11 -14 14 -20 5 -20 13
-20 600 1 554 2 596 18 600 36 9 641 6 707 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
